import React, { useEffect, useRef, useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { capitalize } from 'lodash';

import Image from '../Image';
import CTAButton from '../CTAButton/CTAButton';

const googleLogo = 'social/google-icon-lg.png';
const facebookLogo = 'social/facebook-logo.png';

const getElfsightClass = (platform, single) => {
  if (platform === 'google' && single)
    return 'elfsight-app-872310d8-413a-4768-82ad-078ff70eb3f9';
  if (platform === 'facebook' && single)
    return 'elfsight-app-53091f92-7cec-4e1b-962d-890a30007f53';
  if (platform === 'google')
    return 'elfsight-app-1b62aa20-398c-4dad-bad8-03d771c75796';
  if (platform === 'facebook')
    return 'elfsight-app-c7988e8d-fb2d-4040-8cec-7d1ed09af407';
};

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    'div[class^="Header__Component"]': {
      display: 'flex',
      flex: 1,
      minHeight: 177,
      overflow: 'hidden',
      backgroundColor: '#e9e9e9',
      alignItems: 'center',
    },
    'div[class^="Header__Container"]': {},
    'div[class^="LoadMoreButton__Container"]': {
      display: 'none',
    },
  },
})(() => null);

const ElfsightWidget = React.memo(
  ({ platform, single, onLoad }) => {
    const ref = useRef();
    useEffect(() => {
      if (ref.current) {
        const observer = new MutationObserver(onLoad);
        observer.observe(ref.current, { childList: true });
        return () => {
          observer.disconnect();
        };
      }
    });
    return (
      <>
        <GlobalCss />
        <div ref={ref} className={getElfsightClass(platform, single)} />
      </>
    );
  },
  () => true // always skip rerendering
);

const styles = theme =>
  createStyles({
    root: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        margin: 10,
        maxWidth: 400,
      },
    },
    reviewWrap: {
      position: 'relative',
      flex: 1,
      minHeight: 177,
      overflow: 'hidden',
      backgroundColor: '#fff',
    },
    socialLogoWrapper: {
      display: 'none',
      position: 'absolute',
      top: 27,
      left: 40,
      width: 80,
      height: 80,
      zIndex: 1,
    },
    viewMoreWrap: {
      padding: '0 25px 25pxx',
      backgroundColor: '#fff',
      textAlign: 'center',

      [theme.breakpoints.up(350)]: {
        padding: '0 40px 40px',
      },
    },
  });

const SocialReview = ({
  classes,
  single,
  theme,
  platform,
  source,
  viewMore = true,
}) => {
  const [hasLoaded, setLoaded] = useState(false);
  const handleLoad = () => setLoaded(true);
  const isSSR = typeof window === 'undefined';
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item container direction="column" className={classes.reviewWrap}>
        {hasLoaded ? (
          platform !== 'google' && (
            <div className={classes.socialLogoWrapper}>
              <Image
                relativePath={platform === 'google' ? googleLogo : facebookLogo}
              />
            </div>
          )
        ) : (
          <CircularProgress style={{ margin: 'auto' }} />
        )}
        {!isSSR && (
          <ElfsightWidget
            platform={platform}
            single={single}
            onLoad={handleLoad}
          />
        )}
      </Grid>
      {viewMore && (
        <Grid item className={classes.viewMoreWrap}>
          <CTAButton
            external
            ctaHref={source}
            ctaText={`View more on ${capitalize(platform)}`}
            inverted
            btnStyles={{
              // backgroundColor: theme.palette.primary.main,
              display: 'block',
              margin: 0,
            }}
            
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(SocialReview);
